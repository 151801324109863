<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img background-cata">
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 mx-auto self-center">
            <div>
                <div slot="no-body" class="full-page-bg-color login-box">
                    <div class="vx-row no-gutter justify-center items-center mb-10">
                        <img :src="logoCata" >
                    </div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-full md:w-full mx-auto self-center">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">{{ $t('login.recoverPassword') }}</h4>
                                    <p>{{ $t('login.recoverInstructions') }}</p>
                                </div>

                                <vs-input type="email" label-placeholder="Email" v-model="email" class="w-full mb-8" />
                                <vs-button type="border" to="/pages/login" class="px-4 w-full md:w-auto">{{ $t('login.backLogin') }}</vs-button>
                                <vs-button class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0" @click="recoverPassword">{{ $t('login.recover') }}</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../../http/axios/index.js"
import i18n from '../../i18n/i18n'

export default {
    data() {
        return {
            email: '',
            logoCata: require('@/assets/images/logo/logo_toda_branca.png'),
        }
    },
    methods: {
        recoverPassword() {

            // Loading
            this.$vs.loading()

            axios.post("/auth/password/recovery", {email: this.email})
                .then((data) => {
                    this.$vs.loading.close()
                    if (!data.data.success){
                        this.$vs.notify({
                            title: i18n.t('dialog.error'),
                            text: data.data.error,
                            color: 'danger'
                        })
                    }
                    else{
                        this.$router.push('/pages/login').catch(() => {})
                        this.$vs.loading.close()
                        this.$vs.notify({
                            title: i18n.t('dialog.success'),
                            text: i18n.t('login.recoverOkMessage'),
                            color: 'success'
                        })
                    }
                })
                .catch(error => {
                    this.$vs.loading.close()
                    this.$vs.notify({
                        title: i18n.t('dialog.error'),
                        text: error.message,
                        color: 'danger'
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";

.background-cata {
  background: $cata-bg;
}

.login-box {
  background:none !important;
}

.login-box p, h4 {
  color:white;
}


</style>
